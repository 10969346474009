import WaveSurfer from "wavesurfer.js";
import { connect } from "react-redux";
import { Component } from "react";
import "./index.scss";
import variables from "../../../_export.scss";

const getCSSVariableValue = (variableName) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim();
};

class SoundWave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      isMounted: false,
    };
  }

  componentDidMount() {
    this.mountWave();
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    /* Verify if current sound is playing */
    if (props.playSound._id == this.props.id) {
      /* Same Sound */
      if (props.playSound.toPlay == true) {
        if (props.playHistory.some((e) => e._id == this.props.id)) {
          /* Sound is in history */
          this.restart();
        } else {
          /* Sound is not in history */
          this.handlePlay();
        }
      } else if (props.playSound.toStop == true && !props.playSound.ended) {
        this.handleStop();
      } else if (props.playSound.ended == true) {
        // Do Nothing
      }
    } else {
      /* New Sound */
      /* Stop wave if this is the Previous Sound playing */
      if (props.playSound.prevSound == this.props.id) {
        this.handleStop();
      }
    }
  };

  mountWave = () => {
    const { small, id, soundUrl } = this.props;

    const height = small ? 40 : 100;
    var track = document.getElementById(`track-${id}`);
    this.waveform = WaveSurfer.create({
      barWidth: 3,
      cursorWidth: 1,
      container: `#waveform-${id}`,
      height: 80,
      width: 50,
      progressColor: getCSSVariableValue("--wave-color-progress"),
      url: soundUrl,
      waveColor: getCSSVariableValue("--wave-color"),
      cursorColor: "transparent",
    });
    this.waveform.setVolume(0);
    this.waveform.load(track);
  };

  handlePlay = () => {
    this.setState({ playing: true });
    this.waveform.play();
  };

  restart = () => {
    this.waveform.seekTo(0);
    this.handlePlay();
  };

  handleStop = () => {
    this.setState({ playing: false });
    this.waveform.pause();
  };

  render = () => {
    const { small, id, soundUrl } = this.props;
    const height = small ? 40 : 100;
    const classes = small ? "waveform-small" : "waveform";

    return (
      <div className="wave-absolute">
        <div className={classes} id={`waveform-${id}`} />
        <audio id={`track-${id}`} src={soundUrl} />
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    ref: state.ref,
    playHistory: state.playHistory,
    playSound: state.playSound,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  SoundWave
);
