import React, { useEffect, useRef, useState } from "react";
import { Play, Pause, Loader2 } from "lucide-react";
import "./index.scss";

export function AudioPlayer({
  isPlaying,
  onPlayPause,
  audioUrl,
  isGenerating = false,
}) {
  const audioRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current
          .play()
          .catch((err) => console.error("Audio play error:", err));
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }
  }, [audioUrl]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  return (
    <div className="audio-container">
      {audioUrl && (
        <audio
          ref={audioRef}
          src={audioUrl}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
        />
      )}
      <div className="">
        <div className="play-button-container">
          <span
            onClick={onPlayPause}
            disabled={!audioUrl || isGenerating}
            className=""
          >
            {isPlaying ? <Pause className="" /> : <Play className="" />}
          </span>
          <div>
            {formatTime(currentTime)} / {formatTime(duration || 0)}
          </div>
        </div>
      </div>

      <div className="">
        {isGenerating && (
          <div className="">
            <Loader2 className="" />
          </div>
        )}
      </div>
    </div>
  );
}
