import { Component } from "react";
import "./index.scss";
import { Footer } from "../../../components";
import HeaderContainer from "../../LandingPage/HeaderContainer";
import { MetaTags } from "react-meta-tags";
import PricingBox from "../../AppPage/SubscriptionBox/components/PricingBox";
import { stripePayments } from "../../../../assets/images";
import { Switch } from "@mui/material";
import { LANDING_PAGE_ANNUALY, LANDING_PAGE_MONTHLY } from "../../../../config";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnnual: false, // Default to annual view
      campaignCode: LANDING_PAGE_ANNUALY,
      packs: [],
    };
  }

  componentDidMount() {}

  toggleView = () => {
    this.setState((prevState) => {
      const isAnnual = !prevState.isAnnual;
      return {
        isAnnual,
        campaignCode: isAnnual ? LANDING_PAGE_MONTHLY : LANDING_PAGE_ANNUALY,
      };
    });
  };

  render = () => {
    return (
      <div className="pricing-page">
        <MetaTags>
          <title>Vertate | Pricing</title>
          <meta property="og:title" content="Pricing" />
          <meta name="description" content="Get Started for Free" />
        </MetaTags>
        <HeaderContainer />
        <div className="text-container text-center">
          <h1 className="text-primary">Pricing</h1>
          <h4 className="text-grey">
            Get Access to a library full of quality sounds. Exclusive, Unlimited
            and 100% Royalty Free.
          </h4>
        </div>

        <div className="sd">
          <div className="pricing-container">
            <div
              style={{
                display: "flex",
                gap: "3px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Switch
                checked={this.state.isAnnual}
                onChange={this.toggleView}
                name="subscriptionToggle"
              />
              <p>{this.state.isAnnual ? "Monthly" : "Annual"}</p>
            </div>
            {/* <PricingBox card={1}/> */}
            <PricingBox
              card={2}
              isAnnual={this.state.isAnnual}
              campaignCode={this.state.campaignCode}
            />
          </div>
          <img
            src={stripePayments}
            style={{ width: 150, margin: "20px auto" }}
          ></img>
        </div>
        <Footer width={100} full={false} />
      </div>
    );
  };
}

export default Pricing;
