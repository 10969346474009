import { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import SkeletonContainer from "../../../../components/SkeletonContainer";
import ListContainer from "../../../../components/ListContainer";
class SoundContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render = () => {
    const { sounds } = this.props;
    console.log(sounds);

    // Slice the sounds array to include only the first 10 items
    const displayedSounds = sounds?.length > 0 ? sounds.slice(0, 10) : [];

    return (
      <>
        {displayedSounds.length > 0 ? (
          <div className="sounds-container-promo">
            <h2 className="text-secondary text-center">
              Dive in a Library with over <strong>+100.000 Sounds</strong> 👨‍🚀
            </h2>
            <h3 className="text-grey text-center">
              Exclusive Sounds Every Day
            </h3>
            <div className="promo-sounds">
              <div className=""></div>
              <ListContainer
                total={displayedSounds.length}
                pages={3}
                page={1}
                sounds={displayedSounds}
                isCampaignPage={true}
              />
            </div>
          </div>
        ) : (
          <SkeletonContainer type={"list"} count={20} />
        )}
      </>
    );
  };
}

function mapStateToProps(state) {
  const user = state.user;
  return {
    user,
  };
}

export default connect(mapStateToProps)(SoundContainer);
