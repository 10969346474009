import { TIKTOK_PIXEL, IS_PRODUCTION } from "../../config";
import TikTokPixel from "tiktok-pixel";

const advancedMatching = {};

const options = {
  autoConfig: true,
  debug: false, // enable logs
};

class TikTokPixelClass {
  constructor() {
    if (IS_PRODUCTION) {
      // this.initialize();
    }
  }

  initialize = () => {
    this.pixel = TikTokPixel;
    this.pixel.init(TIKTOK_PIXEL, advancedMatching, options);
  };

  pageview = () => {
    if (!IS_PRODUCTION) {
      return;
    }
    this.pixel.pageView();
  };

  track = (name, data) => {
    if (!IS_PRODUCTION) {
      return;
    }
    this.pixel.track(name, data);
  };

  trackSingle = (name, data) => {
    if (!IS_PRODUCTION) {
      return;
    }
    this.pixel.trackSingle(name, data);
  };
}

const TiktokPixelSingleton = new TikTokPixelClass();

export default TiktokPixelSingleton;
