import React from "react";
import { Clock, Play, Pause, Trash2, Download } from "lucide-react";
import "./index.scss";

export function SoundHistory({
  history,
  currentSound,
  isPlaying,
  onPlay,
  onRemove,
}) {
  const handleDownload = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(blobUrl); // Clean up
      })
      .catch((error) => {
        console.error("Download failed:", error);
      });
  };

  return (
    <div className="history-container">
      <div className="">
        <h2 className="heading-div">
          <Clock />
          History
        </h2>
      </div>
      <div className="body-container">
        {history.map((sound) => (
          <div key={sound._id} className="body-div">
            <div>
              <p>{sound.prompt}</p>
              <p>
                {sound.duration}s •{" "}
                {new Date(sound.register_timestamp).toLocaleString()}
              </p>
            </div>
            <div className="btns-div">
              <span style={{ cursor: "pointer" }} onClick={() => onPlay(sound)}>
                {currentSound === sound._id && isPlaying ? <Pause /> : <Play />}
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleDownload(sound.blob, `${sound.prompt}.mp3`)
                }
              >
                <Download />
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onRemove(sound._id)}
              >
                <Trash2 />
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
