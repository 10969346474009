import React, { Component } from 'react';
import './index.scss';
import FastMarquee from '../../../../components/FastMarquee';
import CountDownTimer from '../../../../components/CountDownTimer';
import { CAMPAIGN_CODE_MONTHLY, CAMPAIGN_CODE_ANNUALLY, CAMPAIGN_CODE } from '../../../../../config';
import CommunityIcon from '../../../../components/CommunityIcon';
import { stripePayments } from '../../../../../assets/images';
import CountUp from 'react-countup';
import { Switch } from '@mui/material';


class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAnnual: false, // Default to annual view
            campaignCode: CAMPAIGN_CODE_ANNUALLY
        };
    }

    toggleView = () => {
        this.setState((prevState) => {
            const isAnnual = !prevState.isAnnual;
            return {
                isAnnual,
                campaignCode: isAnnual ? CAMPAIGN_CODE_ANNUALLY : CAMPAIGN_CODE_MONTHLY
            };
        });
    };

    render = () => {
        let campaignCode = this.state.isAnnual ? CAMPAIGN_CODE_MONTHLY : CAMPAIGN_CODE_ANNUALLY;
        let LINK = `${campaignCode}?prefilled_promo_code=${CAMPAIGN_CODE}`;
        const { packs, totalSounds = 120000 } = this.props;
        const { isAnnual } = this.state; // Access toggle state
        let now = new Date();

        // Adjust discount and subscription details dynamically
        const discount = !isAnnual ? '87%' : '75%';
        const price = !isAnnual ? '$1.97/mo' : '$3.97/mo';
        const subscribeText = !isAnnual
            ? 'Subscribe for $1.97/mo'
            : 'Subscribe for $3.97/mo';

        return (
            <div className='container-promo'>
                <div className='text-center text-main main-container'>
                    <h1 className='title'>
                        Browse from{' '}
                        <strong>
                            <CountUp
                                enableScrollSpy
                                className='h1 text-bold text-primary'
                                end={`+${totalSounds}`}
                                start={`+${totalSounds - 2000}`}
                                duration={300}
                            />
                        </strong>{' '}
                        exclusive sounds.
                    </h1>
                    <h3 className='text-grey' style={{ margin: 'auto', marginTop: 15, width: '80%' }}>
                        The only subscription you need
                    </h3>

                    <div className='subscription-discount-box'>
                        {/* Toggle Buttons */}
                        <div style={{ display: "flex", gap: "3px", alignItems: "center", justifyContent: "center" }}>
                            <Switch
                                checked={this.state.isAnnual}
                                onChange={this.toggleView}
                                name="subscriptionToggle"
                            />
                            <p>{this.state.isAnnual ? "Monthly" : "Annual"}</p>
                        </div>


                        <div className='countdown-box'>
                            <h3 className='text-grey text-center'>{discount} DISCOUNT</h3>
                            <h6 className='text-grey'> Offer Ends in </h6>
                            <h2 className='inline text-primary'>
                                <CountDownTimer
                                    id={'promotion-credits_1'}
                                    date={new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)}
                                />
                            </h2>
                            <h4 className='text-grey-small'>{`Hrs : Mins : Secs`}</h4>

                        </div>
                    </div>

                    <a href={LINK} className='btn btn-animation'>
                        <h3>{subscribeText}</h3>
                    </a>
                    <img src={stripePayments} style={{ width: 150, margin: 'auto', marginTop: 20 }} />

                    <div className='commu-container'>
                        <CommunityIcon />
                    </div>
                    <div className='marquee-container landing-page'>
                        <FastMarquee clickable={false} packs={packs} />
                    </div>
                    <div className='margin-container'>
                        <div className='flex-container text-center'></div>
                    </div>
                </div>
            </div>
        );
    };
}

export default MainContainer;