import React, { useState, useEffect, useRef } from "react";
import { RefreshCw } from "lucide-react";
import { AudioPlayer } from "../../../components/AudioPlayer";
import { SoundHistory } from "../../../components/SoundHistory";
import APIConnection from "../../../api/index";
import "./index.scss";
import { connect } from "react-redux";
import UserAPI from "../../../classes/User";
import Loading from "../../../components/Loading";
import { useNavigate } from "react-router-dom";

const GenerateSound = (props) => {
  const { user } = props;
  const [prompt, setPrompt] = useState("");
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);
  const [currentSoundId, setCurrentSoundId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const fetchSoundHistory = async () => {
    try {
      setIsLoading(true);
      let res = await UserAPI.fetchSoundHistory({ user_id: user?._id });
      const fetchedHistory = res.map((sound) => ({
        ...sound,
      }));
      setHistory(fetchedHistory);
    } catch (err) {
      console.error("Error fetching sound history:", err);
      setError("Failed to fetch sound history.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setError(null);
    fetchSoundHistory();
  }, []);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      setError("Please enter a description for the sound effect.");
      return;
    }

    setError(null);
    setIsGenerating(true);
    setIsPlaying(false);

    try {
      const audioBlob = await APIConnection.generateSoundEffects(
        prompt,
        duration
      );

      if (!audioBlob || !(audioBlob instanceof Blob)) {
        throw new Error("Failed to generate a valid sound effect.");
      }

      const newSound = {
        id: crypto.randomUUID(),
        prompt: prompt.trim(),
        duration,
        blob: audioBlob,
        createdAt: new Date(),
      };

      setHistory((prev) => [newSound, ...prev]);

      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      setCurrentSoundId(newSound.id);

      const base64Blob = await blobToBase64(audioBlob);

      await UserAPI.createSoundHistory({
        user_id: user?._id,
        prompt: prompt.trim(),
        duration,
        blob: base64Blob,
      });

      if (!user?.subscription) {
        await UserAPI.updateFreeTierCredits({ id: user?._id });
      } else {
        await UserAPI.updateCredits({ id: user?._id });
      }

      await fetchSoundHistory();
    } catch (error) {
      console.error("Error generating sound:", error);
      setError(
        error instanceof Error ? error.message : "An unexpected error occurred."
      );
      setAudioUrl(null);
    } finally {
      setIsGenerating(false);
    }
  };

  const handlePlayHistorySound = (sound) => {
    try {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      const url = sound.blob;
      setAudioUrl(url);
      setCurrentSoundId(sound._id);
      setIsPlaying(true);

      const audio = new Audio(url);
      audioRef.current = audio;

      audio
        .play()
        .then(() => {
          console.log(`Playing sound with prompt: ${sound.prompt}`);
        })
        .catch((err) => {
          console.error("Error playing sound:", err);
        });
    } catch (err) {
      console.error("Error playing sound:", err);
      setError(
        err.message || "An error occurred while trying to play the sound."
      );
    }
  };

  useEffect(() => {
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  const handleRemoveHistory = async (id) => {
    setError(null);
    setIsLoading(true);
    try {
      await UserAPI.deleteSoundHistory({
        sound_id: id,
      });

      await fetchSoundHistory();
    } catch (err) {
      console.error("Error removing sound history:", err);
      setError(
        err instanceof Error ? err.message : "An unexpected error occurred."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleProClick = async (proStatus) => {
    try {
      // let res = await UserAPI.updateToPro({
      //   id: user?._id,
      //   proStatus,
      // });

      // if (res.status != 200) {
      //   throw new Error(res.message);
      // }

      navigate("/app/subscribe");
    } catch (err) {
      //Handle Err with UI
      console.log(err);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="main-container">
      {
        !user?.subscription ? (
          <div className="pro-container">
            <div>
              <span>{user?.freeTierCredits} sounds remaining in free tier</span>
            </div>
            <div onClick={() => handleProClick(true)}>
              <span className="upgrade-button">Upgrade to Pro</span>
            </div>
          </div>
        )
        : (
          <div className="pro-container">
            <div>
              <span>5 credits will consume to generate one sound</span>
            </div>
            {/* <div onClick={() => handleProClick(false)}>
              <span className="upgrade-button">Back to Free</span>
            </div> */}
          </div>
        )
      }

      <textarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Describe the sound effect you want to generate (e.g., 'Cyberpunk laser blast', 'Magical sparkle effect')"
        className="promp-textarea"
      />
      <label className="duration-container">
        <span>Duration (seconds)</span>
        <input
          type="number"
          min="1"
          max="30"
          value={duration}
          onChange={(e) =>
            setDuration(Math.max(1, Math.min(30, Number(e.target.value))))
          }
          className="duration-input"
        />
      </label>
      {error && <div className="error-div">{error}</div>}
      <button
        onClick={handleGenerate}
        disabled={
          isGenerating ||
          !prompt.trim() ||
          (user?.isPro ? user?.credits < 5 : user?.freeTierCredits <= 0) ||
          duration <= 0
        }
        className="generate-btn"
      >
        <RefreshCw
          className={`w-5 h-5 mr-2 ${isGenerating ? "animate-spin" : ""}`}
        />
        {isGenerating ? "Generating..." : "Generate Sound Effect"}
      </button>
      <AudioPlayer
        isPlaying={isPlaying}
        onPlayPause={() => setIsPlaying(!isPlaying)}
        audioUrl={audioUrl}
        isGenerating={isGenerating}
      />
      <SoundHistory
        history={history}
        currentSound={currentSoundId}
        isPlaying={isPlaying}
        onPlay={handlePlayHistorySound}
        onRemove={handleRemoveHistory}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(GenerateSound);
