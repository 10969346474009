import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import AccountPage from "./routes/AppPage/AccountPage";
import AppPage from "./routes/AppPage";
import SignUpPage from "./routes/AppPage/SignUpPage";
import PackPage from "./routes/AppPage/PackPage";
import SearchPage from "./routes/AppPage/SearchPage";
import LibraryPage from "./routes/AppPage/LibraryPage";
import SubscriptionBox from "./routes/AppPage/SubscriptionBox";
import FavouritesPage from "./routes/AppPage/FavouritesPage";
import CustomSwitch from "../app/components/CustomSwitch";
import HomePage from "./routes/AppPage/HomePage";
import ResetPasswordPage from "./routes/AppPage/ResetPasswordPage";
import LandingPage from "./routes/LandingPage";
import TermsAndServices from "./routes/Utils/TermsAndServices";
import PrivacyPolicy from "./routes/Utils/PrivacyPolicy";
import RefundPolicy from "./routes/Utils/Refund Policy";
import Pricing from "./routes/Utils/Pricing";
import Campaign10GBPage from "./routes/CampaignPage/Campaign10GBPage";
import AfterPurchasePage from "./routes/AfterPurchasePage";
import GenerateSoundPage from "./routes/AppPage/GenerateSoundPage";

export default function RouteIndex() {
  return (
    <BrowserRouter>
      <CustomSwitch>
        <Route path="app" element={<AppPage />}>
          <Route path="home" element={<HomePage />} />
          {/* To make a Initial Page for App with Marketing Content */}
          <Route path="search" element={<SearchPage />} />
          <Route path="search/pack" element={<PackPage />} />
          <Route path="search/user" element={<FavouritesPage />} />
          <Route path="account" element={<AccountPage />} />
          <Route path="subscribe" element={<SubscriptionBox />} />
          <Route path="library" element={<LibraryPage />} />
          <Route path="favorites" element={<FavouritesPage />} />
          <Route path="generate_sound" element={<GenerateSoundPage />} />
        </Route>
        <Route path="/" element={<LandingPage />} />
        <Route path="campaign-10gb" element={<Campaign10GBPage />} />
        <Route path="after-purchase" element={<AfterPurchasePage />} />
        <Route path="terms-and-services" element={<TermsAndServices />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="signup" element={<SignUpPage />} />
        <Route path="resetPassword" element={<ResetPasswordPage />} />
        <Route path="*" element={<AppPage />} />
      </CustomSwitch>
    </BrowserRouter>
  );
}

export const ROUTES = [
  {
    path: "/singup",
    key: "signup",
    breadcrumb: "Signup",
  },
  {
    path: "/app/home",
    key: "home",
    breadcrumb: "Home",
  },
  {
    path: "/app/search",
    breadcrumb: "Search",
    key: "search",
    children: [
      {
        path: "/app/search/pack",
        breadcrumb: "Pack",
      },
      {
        path: "/app/user",
        breadcrumb: "User",
      },
    ],
  },
  {
    path: "/app/library",
    key: "library",
    breadcrumb: "My Library",
  },
  {
    path: "/app/favorites",
    key: "favorites",
    breadcrumb: "Favorites",
  },
  {
    path: "/app/generate_sound",
    key: "generate_sound",
    breadcrumb: "Generate Sound",
  },
  {
    path: "/app/subscribe",
    key: "subscribe",
    breadcrumb: "Subscribe",
  },
  {
    path: "/app/resetPassword",
    key: "resetPassword",
    breadcrumb: "Reset Password",
  },
  {
    path: "/app/account",
    key: "account",
    breadcrumb: "Account",
  },
  {
    path: "/campaign-10gb",
    key: "campaign-10gb",
    breadcrumb: "Campaign",
  },
];
