import "./index.scss";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { stripePayments } from "../../../../assets/images";
import { useNavigate } from "react-router-dom";
import GoogleAnalyticsSingleton from "../../../classes/GoogleAnalytics";
import { MetaTags } from "react-meta-tags";
import PricingBox from "./components/PricingBox";
import CountDownTimer from "../../../components/CountDownTimer";
import { Switch } from "@mui/material";
import { LANDING_PAGE_ANNUALY, LANDING_PAGE_MONTHLY } from "../../../../config";

const SubscriptionBox = (props) => {
  const navigate = useNavigate();
  const [isAnnual, setIsAnnual] = useState(false);
  const [campaignCode, setCampaignCode] = useState(LANDING_PAGE_ANNUALY);
  const user = props.user;
  const isSigned = user && user.email ? true : false;
  var timeLeft = 0;
  var isInPromotion = false;

  if (isSigned) {
    timeLeft =
      new Date(user.register_timestamp) - Date.now() + 24 * 60 * 60 * 1000;
    isInPromotion = timeLeft > 0;
  }

  useEffect(() => {
    GoogleAnalyticsSingleton.pageview("subscribe");
  }, []);

  const toggleView = () => {
    setIsAnnual((prevIsAnnual) => {
      const newIsAnnual = !prevIsAnnual;
      setCampaignCode(
        newIsAnnual ? LANDING_PAGE_MONTHLY : LANDING_PAGE_ANNUALY
      );
      return newIsAnnual;
    });
  };

  return (
    <>
      <MetaTags>
        <title>Subscribe</title>
        <meta name="description" content="Subscribe today and get 60% OFF" />
        <meta property="og:title" content="Subscribe" />
      </MetaTags>
      <div className="subscription-box outer-div">
        <div className="closeIcon">
          <button onClick={() => navigate(-1)}>
            <CloseOutlined />
          </button>
        </div>
        <div className="inner-div">
          <h2> Exclusive, Unlimited & 100% Royalty Free Platform✌️ </h2>
          <h4 className="text-grey">
            {" "}
            Subscribe and get access to monthly credits that never ends..
          </h4>
          {isInPromotion ? (
            <div className="subscription-discount-box">
              <div className="tag-outline">
                <h3 className="text-primary">
                  60% OFF{" "}
                  <span className="text-grey">
                    {"ends in "}
                    <CountDownTimer
                      id={"promotion-credits_2"}
                      date={Date.now() + timeLeft}
                    />{" "}
                  </span>
                </h3>
              </div>
            </div>
          ) : null}
          <div className="inline-items">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  gap: "3px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Switch
                  checked={isAnnual}
                  onChange={toggleView}
                  name="subscriptionToggle"
                />
                <p>{isAnnual ? "Monthly" : "Annual"}</p>
              </div>
              {/* 1st Package */}
              {/* <PricingBox card={1}/> */}
              {/* 2nd Package */}
              <PricingBox
                card={2}
                isAnnual={isAnnual}
                campaignCode={campaignCode}
              />
            </div>
          </div>
          <img src={stripePayments} className="stripe-img" />
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    notification: state.notification,
    user: state.user,
  };
}

export default connect(mapStateToProps)(SubscriptionBox);
