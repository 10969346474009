import "./index.scss";
import { connect } from "react-redux";
import {
  GraphicEqOutlined,
  SupportOutlined,
  CardGiftcard,
  TollOutlined,
  VerifiedUser,
  Fingerprint,
  LockClock,
} from "@mui/icons-material";
import { STRIPE_CODE, SUBSCRIPTION_STARTER_URL } from "../../../../../config";
import { logo_color, logo_grey } from "../../../../../assets/images";

const PricingBox = (props) => {
  const user = props.user;
  const isSigned = user && user.email ? true : false;
  var timeLeft = 0;
  var isInPromotion = false;
  var stringStripeCode = "";

  if (isSigned) {
    timeLeft =
      new Date(user.register_timestamp) - Date.now() + 24 * 60 * 60 * 1000;
    isInPromotion = timeLeft > 0;
    stringStripeCode = `&prefilled_promo_code=${STRIPE_CODE}`;
  }

  const priceRenderDiscount = ({ integer, decimals }) => {
    return (
      <div className="price-container">
        <div className="inline">
          <h3 className="text-primary decimal">$</h3>
        </div>
        <h1 className="inline text-primary">{integer}</h1>
        <div className="inline">
          <h4 className="text-primary decimal">{decimals}</h4>
          <h4 className="text-primary decimal">/mo</h4>
        </div>
      </div>
    );
  };

  const prevPriceRender = ({ integer, decimals }) => {
    return (
      <div className="price-container">
        <div className="inline">
          <h5 className="text-grey decimal text-striked">$</h5>
        </div>
        <h5 className="inline text-grey text-striked">{integer}</h5>
        <div className="inline">
          <h5 className="text-grey decimal text-striked">{decimals}</h5>
          <h5 className="text-grey decimal text-striked">/mo</h5>
        </div>
      </div>
    );
  };

  const priceRender = ({ integer, decimals, isAnnual }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="price-container">
          <div className="inline">
            <h3 className="text-grey decimal">$</h3>
          </div>
          <h1 className="inline">{integer}</h1>
          <div className="inline">
            <h4 className="text-grey decimal">{decimals}</h4>
            <h4 className="text-grey decimal">/mo</h4>
          </div>
        </div>
        <div>
          {isAnnual ? (
            <span>(first 3 months discount of 40%) then 7,99$</span>
          ) : (
            <span>(40% Discount - 58$/year)</span>
          )}
        </div>
      </div>
    );
  };

  const renderTagPromotion = ({ icon, text, smallText, soon }) => {
    return (
      <div className="flex-container tag-container-price promotion-tag">
        {icon}
        <h4 className="text-desc">
          {text}
          <span className="span-desc">{smallText}</span>
        </h4>
      </div>
    );
  };

  const renderTag = ({ icon, text, smallText, soon }) => {
    return (
      <div className="flex-container tag-container-price">
        {icon}
        <h4 className="text-desc">
          {text}
          <span className="span-desc">{smallText}</span>
        </h4>
        {soon ? <h5 className="tag tag-cont">Soon</h5> : null}
      </div>
    );
  };

  const renderCardOne = () => {
    return (
      <div className="subscription-card">
        <div className="sub-container">
          <img src={logo_grey} className="icon" />
          <h2>Starter</h2>
        </div>
        {isInPromotion ? (
          <>
            {priceRenderDiscount({ integer: 3, decimals: ",97" })}
            {prevPriceRender({ integer: 7, decimals: ",99" })}
          </>
        ) : (
          priceRender({ integer: 7, decimals: ",99" })
        )}
        <div className="description-container">
          {renderTag({
            icon: <GraphicEqOutlined className="icon-desc" />,
            text: "100,000 Sounds",
            smallText: "Access",
          })}
          {renderTag({
            icon: <TollOutlined className="icon-desc" />,
            text: "450 Credits",
            smallText: "Every Month",
          })}
          {renderTag({
            icon: <VerifiedUser className="icon-desc" />,
            text: "100% Royalty Free",
            smallText: "Forever",
          })}
          {renderTag({
            icon: <SupportOutlined className="icon-desc" />,
            text: "Customer Support",
            smallText: "Limited",
          })}
          {renderTagPromotion({
            icon: <CardGiftcard className="icon-desc" />,
            text: "25 Credits",
            smallText: "Bonus",
          })}
          <a
            href={
              !isSigned
                ? "/signup"
                : `${SUBSCRIPTION_STARTER_URL}?prefilled_email=${user.email}` +
                  stringStripeCode
            }
            className="btn btn-sub"
          >
            <h4>Subscribe</h4>
          </a>
          <h5 className="text-grey">Try for 7 Days</h5>
        </div>
      </div>
    );
  };

  const renderCardTwo = () => {
    return (
      <div className="subscription-card best-deal">
        <div className="sub-container">
          <img src={logo_color} className="icon" />
          <h2>Producer</h2>
        </div>
        {/* {isInPromotion ? ( */}
        {props.isAnnual
          ? //   <>
            //     {priceRenderDiscount({ integer: 19, decimals: ",97" })}
            //     {prevPriceRender({ integer: 49, decimals: ",99" })}
            //   </>
            priceRender({
              integer: 4,
              decimals: ",79",
              isAnnual: props.isAnnual,
            })
          : priceRender({
              integer: 4,
              decimals: ",83",
              isAnnual: props.isAnnual,
            })}
        <div className="description-container">
          {renderTag({
            icon: <GraphicEqOutlined className="icon-desc" />,
            text: "100.000 Sounds",
            smallText: "Access",
          })}
          {renderTag({
            icon: <Fingerprint className="icon-desc" />,
            text: "Sample Generator",
            smallText: "Access",
          })}
          {renderTag({
            icon: <TollOutlined className="icon-desc" />,
            text: "150 Credits",
            smallText: "Every Month",
          })}
          {renderTag({
            icon: <VerifiedUser className="icon-desc" />,
            text: "100% Royalty Free",
            smallText: "Forever",
          })}
          {/* {renderTag({
            icon: <EarbudsOutlined className="icon-desc" />,
            text: "VertPRO Mastering",
            smallText: "VST",
            soon: true,
          })}
          {renderTag({
            icon: <PianoOutlined className="icon-desc" />,
            text: "VertPRO Synth",
            smallText: "VST",
            soon: true,
          })} */}
          {renderTag({
            icon: <SupportOutlined className="icon-desc" />,
            text: "Customer Support",
            // smallText: "7 Days/Week",
            smallText: "Limited",
          })}
          {/* {renderTagPromotion({
            icon: <CardGiftcard className="icon-desc" />,
            text: "500 Credits",
            smallText: "Bonus",
          })} */}
          <a
            // href={
            //   !isSigned
            //     ? "/signup"
            //     : `${SUBSCRIPTION_PRODUCER_URL}?prefilled_email=${user.email}` +
            //       stringStripeCode
            // }
            href={props.campaignCode}
            className="btn btn-primary btn-sub"
          >
            <div className="btn-subscribe">
              <LockClock className="icon-desc" />
              <h4>Subscribe</h4>
            </div>
          </a>
          {/* <h5 className="text-grey">Try for 7 Days</h5> */}
        </div>
      </div>
    );
  };

  if (props.card === 1) {
    return renderCardOne();
  }
  if (props.card === 2) {
    return renderCardTwo();
  }
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(PricingBox);
