import axios from "axios";
import { API_URL, ELEVEN_LABS_API_KEY } from "../../config";

const URL = API_URL;
const API_KEY = ELEVEN_LABS_API_KEY;

export const methods = {
  async googleLogin(params) {
    return await axios.post(`${URL}/api/users/googleAuth`, params);
  },
  async registerUser(params) {
    return await axios.post(`${URL}/api/users/register`, params);
  },
  async loginUser(params) {
    return await axios.post(`${URL}/api/users/login`, params);
  },
  async updateFreeTier(params) {
    return await axios.post(`${URL}/api/users/updateFreeTier`, params);
  },
  async updateCredits(params) {
    return await axios.post(`${URL}/api/users/updateCredits`, params);
  },
  async updateToPro(params) {
    return await axios.post(`${URL}/api/users/updateToPro`, params);
  },
  async createSoundHistory(params) {
    return await axios.post(
      `${URL}/api/soundHistory/createSoundHistory`,
      params
    );
  },
  async deleteSoundHistory(params) {
    return await axios.post(
      `${URL}/api/soundHistory/deleteSoundHistory`,
      params
    );
  },
  async fetchSoundHistory(params) {
    return await axios.post(
      `${URL}/api/soundHistory/fetchSoundHistory`,
      params
    );
  },
  async authUser(params) {
    const { bearerToken, user_id } = params;
    return await axios.post(
      `${URL}/api/users/auth`,
      { user_id },
      addOpts(bearerToken, user_id)
    );
  },
  async requestResetPassword(params) {
    return await axios.post(`${URL}/api/users/password/request`, params);
  },
  async resetPassword(params) {
    return await axios.post(`${URL}/api/users/password/reset`, params);
  },
  async getFilters() {
    return await axios.post(`${URL}/api/filters/get`, {});
  },
  async getStats({ type, timeline }) {
    return await axios.post(`${URL}/api/stats/get`, { type, timeline });
  },
  async getTops({ type, timeline }) {
    return await axios.post(`${URL}/api/tops/get`, { type, timeline });
  },
  async getSounds(params) {
    return await axios.post(`${URL}/api/sounds/get`, params);
  },
  async getPacks(params) {
    return await axios.post(`${URL}/api/packs/get`, params);
  },
  async getPackById(params) {
    return await axios.post(`${URL}/api/packs/getById`, params);
  },

  async searchBySound(params) {
    const { bearerToken, user_id, filter } = params;
    return await axios.post(
      `${URL}/api/sounds/search`,
      { user_id, ...filter },
      addOpts(bearerToken, user_id)
    );
  },
  async searchByPack(params) {
    const { bearerToken, user_id, filter } = params;
    return await axios.post(
      `${URL}/api/packs/search`,
      { user_id, ...filter },
      addOpts(bearerToken, user_id)
    );
  },
  async getUsersSounds(params) {
    const { bearerToken, user_id, filter } = params;
    return await axios.post(
      `${URL}/api/users/sounds`,
      { user_id, ...filter },
      addOpts(bearerToken, user_id)
    );
  },
  async getUsersPacks(params) {
    const { bearerToken, user_id } = params;
    return await axios.post(
      `${URL}/api/users/packs`,
      { user_id },
      addOpts(bearerToken, user_id)
    );
  },
  async getMyFavoritesSounds(params) {
    const { bearerToken, user_id, isUser } = params;
    return await axios.post(
      `${URL}/api/users/sounds/favorites`,
      { user_id, isUser },
      addOpts(bearerToken, user_id)
    );
  },
  async getMyFavoritesPacks(params) {
    const { bearerToken, user_id, isUser } = params;
    return await axios.post(
      `${URL}/api/users/packs/favorites`,
      { user_id, isUser },
      addOpts(bearerToken, user_id)
    );
  },
  async buySound(params) {
    const { bearerToken, user_id, id } = params;
    return await axios.post(
      `${URL}/api/sounds/buy`,
      { user_id, id },
      addOpts(bearerToken, user_id)
    );
  },
  async buyPack(params) {
    const { bearerToken, user_id, id } = params;
    return await axios.post(
      `${URL}/apvi/packs/buy`,
      { user_id, id },
      addOpts(bearerToken, user_id)
    );
  },
  async addSoundToFavorites(params) {
    const { bearerToken, user_id, id } = params;
    return await axios.post(
      `${URL}/api/sounds/favorite`,
      { user_id, id },
      addOpts(bearerToken, user_id)
    );
  },
  async getUsersMostFavorites(params) {
    return await axios.post(`${URL}/api/sounds/favorites`, {});
  },
  async addPackToFavorites(params) {
    const { bearerToken, user_id, id } = params;
    return await axios.post(
      `${URL}/api/packs/favorite`,
      { user_id, id },
      addOpts(bearerToken, user_id)
    );
  },
  async generateSoundEffects(text, duration) {
    const model_id = "eleven_sfx_v1";
    const duration_seconds = duration;
    const prompt_influence = 0.3;

    if (!API_KEY) {
      throw new Error(
        "API key not configured. Please check your environment variables."
      );
    }

    if (!text || !text.trim()) {
      throw new Error("Please provide a description for the sound effect.");
    }

    try {
      const response = await axios.post(
        "https://api.elevenlabs.io/v1/sound-generation",
        {
          text: text.trim(),
          model_id,
          duration_seconds,
          prompt_influence,
        },
        {
          headers: {
            Accept: "audio/mpeg",
            "Content-Type": "application/json",
            "xi-api-key": API_KEY,
          },
          responseType: "blob", // Ensure response is treated as a binary Blob
        }
      );
      console.log(response);

      if (response.status !== 200) {
        let errorMessage = `API Error: ${response.status} ${response.statusText}`;
        if (response.data && response.data.detail?.message) {
          errorMessage = response.data.detail.message;
        }
        throw new Error(errorMessage);
      }

      if (!response.data || response.data.size === 0) {
        throw new Error("Generated audio is empty. Please try again.");
      }

      return response.data; // Returns the Blob of audio data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.detail?.message || error.message);
      }
      throw new Error(
        "Failed to generate sound effect. Please try again later."
      );
    }
  },
};

function addOpts(bearerToken, user_id) {
  return {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + bearerToken,
      payload: JSON.stringify({ id: user_id }),
    },
  };
}
